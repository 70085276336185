.ant-input,
.ant-input:focus, 
.ant-input:hover,
.ant-input-focused,
.ant-input-affix-wrapper:focus, 
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border: 1px solid $light-grey !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
  .ant-select-selector {
    box-shadow: none !important;
    border: 1px solid $light-grey !important;
  }
}

.ant-input.rounded-input, .ant-input[type=password]  {
  border-radius: 6px !important;
  height: 52px;
  background-color: $white;
}

.ant-input-focused {
  box-shadow: none;
}

.ant-input-affix-wrapper {
  border: 0;
}

.ant-input-affix-wrapper.ant-input-password {
  padding: 0;

  .ant-input-suffix {
    width: 0;
    position: relative;
    left: -35px;
  }
}

.ant-input[type=password],
.ant-input-affix-wrapper > input.ant-input,
.ant-input-affix-wrapper.ant-input-password {
    padding: 6px 11px;
}

.ant-form {
  .ant-form-item {
    .ant-form-item-label, .ant-form-item-control {
      @include mq($until: mobileLandscape) {
        flex: unset;
      }
    }
  }
}

#login-form {
  .ant-form-item-control {
    .ant-form-item-explain-error {
      div[role="alert"] {
        visibility: hidden;
      }
    }
  }
}

.ant-avatar {
  background: unset;
}

.ant-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant-dropdown-placement-bottomCenter {
  .ant-dropdown-menu {
    background: #F4F4F4;
    border-radius: 0 0 24px 24px;
    box-shadow: unset;
    // padding-top: 30px;

    .ant-dropdown-menu-item {
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.16px;
      margin: 15px;
    }

    .ant-dropdown-menu-title-content {
      padding: 5px;

      .dropdown-item {
        padding: 8px 20px;
        &:hover {
          background: #171717;
          border-radius: 41px;
          color: $white;
        }
      }
    }
  }
}


.ant-modal-content {
  .ant-modal-footer {
    border-top: none;
  }
}

.ant-card.ant-card-bordered {
  box-shadow: 0px 4px 5px 2px rgba(155, 147, 147, 0.25);
  border-radius: 9px;
  height: 370px;
}

.ant-input-affix-wrapper {
  width: 100%;
  background: $white;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 7px;
  height: 52px;

  .ant-input-prefix {
    margin-right: 15px;
    margin-left: 5px;
  }

  .ant-input.ant-input-lg {
    height: auto;
  }
}

.ant-tabs {
  .ant-tabs-nav-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 25px;

    .ant-tabs-ink-bar {
      background: transparent;
    }
  }
  .ant-tabs-tab {
    display: flex;
    justify-content: center;
    margin-left: 0 !important;
    width: 180px;
    border-bottom: 3px solid #D1D1D1;

    .ant-tabs-tab-btn {
      font-weight: 600;
      font-size: 21px;
      line-height: 30px;
      color: #D1D1D1;
    }

    &.ant-tabs-tab-active:nth-child(1) {
      border-bottom: 3px solid $blue;

      .ant-tabs-tab-btn {
        color: $blue;
      }
    }

    &.ant-tabs-tab-active:nth-child(2) {
      border-bottom: 3px solid $orange;

      .ant-tabs-tab-btn {
        color: $orange;
      }
    }

    &.ant-tabs-tab-active:nth-child(3) {
      border-bottom: 3px solid $green;

      .ant-tabs-tab-btn {
        color: $green;
      }
    }

    @include mq($until: mobileLandscape) {
      width: 90px;

      .ant-tabs-tab-btn {
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        color: #D1D1D1;
      }
    }
  }
}

.ant-list-items {
  display: flex;
  flex-direction: row;
}

.ant-list-grid {
  width: 100%;
  .ant-row {
    .ant-col {
      .ant-list-item {
        display: flex;
      }
    }
  }
}

.ant-list-pagination, .ant-table-pagination.ant-pagination {
  margin-top: 40px;
}

.ant-pagination {
  .ant-pagination-item {
    display: none;
  }
  .ant-pagination-prev, .ant-pagination-next {
    width: 33px;
    height: 33px;
    background: $blue;
    border-radius: 100px;
  }
  .ant-pagination-item-link {
    width: 33px;
    height: 33px;
    background: $blue;
    border-radius: 100px;
    min-width: unset;
    border: unset;
    display: flex;

    & > span > svg {
      fill: $white;
      width: 1.2em;
      height: 1.3em;
    }

    &:hover {
      transform: unset;
    }
  }
}


.ant-steps-navigation .ant-steps-item::after {
  margin-left: 1px;
  margin-top: -11px;
}

.ant-table-ping-left {
  .ant-table-cell-fix-left-first, .ant-table-cell-fix-left-last {
    &::after {
        box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.05);
    }
  }
}

.ant-upload-list {

  .ant-upload-list-item-info {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
  }

  .ant-upload-list-item-name {
    color: $black;
    font-weight: normal;

    &:hover {
      color: $black;
      text-decoration: none;
    }
  }

  .ant-upload-list-item-card-actions-btn.ant-btn-sm {
    opacity: 1;

    &:hover {
      background: unset;
      background-color: unset;
      border: unset;
      transform: unset;
      outline: none;
      color: rgba(0, 0, 0, 0.85);
      border-color: transparent;
    }
  }

  .ant-upload-list-text-container {
    margin: 0 0 15px;
    padding: 0 0 10px;
  }
}

.ant-upload-list-item-progress {
  padding-left: 40px;
  padding-right: 90px;
  bottom: -20px;
}

.ant-image-preview-img {
  background-color: #FFFFFF;
}

.ant-picker {
  margin: 20px 0;
}