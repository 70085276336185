.signUp-main-section {
  max-width: 470px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 0;

  h1, h2 {
    width: 100%;
    text-align: center;
  }

  .signUpType-switcher {
    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .role-logo-wrapper {
    width: 180px;
    height: 180px;
    border-radius: 1000px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &.manager {
      background: #F5EAFE;

      > img {
        width: 78.65px;
        height: 101px;
      }
    }

    &.user {
      background: rgba(36, 124, 227, 0.1);

      > img {
        width: 107px;
        height: 97.32px;
      }
    }
  }

  form {
    margin-top: 20px;
    width: 412px;

    @include mq($until: mobileLandscape) {
      width: 350px;
    }

    @include mq($until: mobilePortrait) {
      width: 320px;
    }
  }

  #add-class-form {
    .ant-form-item-control-input-content {
      .input-wrapper-dynamic {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;

        .dynamic-delete-button {
          left: -30px;
          position: relative;
          width: 0;
        }
      }
    }
  }

  #register-form {
    display: flex;
    flex-direction: column;
  }

  .name-inputs-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 35px;
    width: 100%;

    .name-input {
      width: 46%;
    }

    @include mq($until: mobileLandscape) {
      flex-direction: column;

      .name-input {
        width: 100%;
      }
    }
  }

  .add-class-at-signup {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    .add-class-label {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #171717;
    }

    .add-class-hint {
      font-size: 16px;
      line-height: 19px;
      margin: 10px 0;
      color: rgba(134, 146, 166, 0.8);
    }

    .add-class-btn {
      font-size: 16px;
      line-height: 19px;
      text-decoration-line: underline;
      color: $purple;
      cursor: pointer;
    }

    .right-col {
      min-width: 90px;
    }
  }

  .errors-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .form-error {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      width: 412px;
      height: auto;
      min-height: 35px;

      background: rgba(240, 94, 35, 0.05);
      border: 1px solid $dark-orange;
      border-radius: 6px;
      margin: 5px 0;
      padding: 15px 5px;

      span {
        width: 330px;
        text-align: left;
        line-height: 16px;
      }

      @include mq($until: mobileLandscape) {
        width: 350px;

        span {
          width: 280px;
        }
      }

      @include mq($until: mobilePortrait) {
        width: 320px;

        span {
          width: 250px;
        }
      }
    }
  }

  .register-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .back-btn-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    margin: 20px 0 30px;

    .role-label {
      margin-left: 30px;
      font-weight: 500;
      font-size: 30px;
      line-height: 36px;
      display: flex;
      align-items: center;
      text-align: center;

      &.manager {
        color: $purple;
      }
      &.user {
        color: $blue;
      }

      @include mq($until: tabletPortrait) {
        font-size: 24px;
        line-height: 29px;
      }

      @include mq($until: mobileLandscape) {
        font-size: 21px;
        line-height: 29px;
      }
    }
  }

  .join-class-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .ant-row {
      max-width: 410px;
      width: 100%;
    }
  }

  .join-class-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      margin: 0;
      padding: 0;
      font-weight: 500;

      &.class-title {
        color: $blue;
      }
    }
  }

  &.reset-password {
    .sign-in-switcher {
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      color: $black;
    }
    .email-hint {
      text-align: left;
      width: 412px;
      margin: 40px 0 15px;
    }
    .reset-password-btn {
      margin: 0 auto;
    }

    @include mq($until: mobileLandscape) {
      .email-hint {
        width: 350px;
      }

      .reset-password-btn {
        height: 70px;
        line-height: 25px;
      }
    }

    @include mq($until: mobilePortrait) {
      .email-hint {
        width: 320px;
      }
    }
  }

  &.manager {
    max-width: 500px;

    .class-title {
      font-weight: bold;
      font-size: 30px;
      line-height: 41px;
      text-align: center;
      color: $blue;
      padding: 0;
      margin-top: 0;
    }

    .hint {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #5A5A5A;
      text-align: center;
      margin: 20px 0 30px;
      max-width: 345px;
    }
  }

  @include mq($until: mobileLandscape) {
    max-width: 350px;
    padding: 25px 30px;

  }
}

.role-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 600px;
  margin: 50px 0;

  .select-role-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 289px;
    height: 268px;
    border-radius: 13px;
    cursor: pointer;
    transition: all .2s !important;
    
    &:hover {
      transform: scale(1.06) !important;
    }

    h3 {
      margin-top: 30px;
      display: flex;
      align-items: center;
      text-align: center;
    }

    &.role-user {
      background: rgba(36, 124, 227, 0.1);

      h3 {
        color: $blue;
      }
    }
  
    &.role-manager {
      background: rgba(157, 41, 249, 0.1);

      h3 {
        color: $purple;
      }
    }
  }

  @media (max-width: 650px) {
    flex-direction: column;
    margin: 30px 0;
    row-gap: 30px;
  }
}

.astronaut {
  position: absolute;
  transform: rotate(-16deg);
  width: auto;
  height: 250px;
  left: 88%;
  top: 25%;
  overflow: none;

  @include mq($until: tabletLandscape) {
    left: 83%;
  }

  @include mq($until: tabletPortrait) {
    display: none;
  }
}