.signUp-btn {
	margin-bottom: 10px;
	font-size: 16px;
	transition: all .2s ease-in-out;
}

.signUp-btn:hover {
	transform: scale(1.03);
}

.signUp-main-section > form {
	margin-top: 10px;
}

button:not(.select-role-btn):hover {
	opacity: 1;
}

.google-login-btn{
	transition: all .2s ease-in-out;
	font-size: 16px;
}

.microsoft-login-btn{
	transition: all .2s ease-in-out;
	font-size: 16px;
}
.google-login-btn:hover{
	transform: scale(1.04);
}
.microsoft-login-btn:hover{
	transform: scale(1.04);
}

#login-form_password {
  padding: 6.5px 11px;
  padding-top: 6.5px;
  padding-right: 11px;
  padding-bottom: 6.5px;
  padding-left: 11px;
}

.ant-input[type=text]{
	border: 1px solid $light-grey;
  border-radius: 6px;
  height: 52px;
}

.forgot-link {
	padding-top: 8px;
}

.have-account{
	font-size: 16px;
	margin: 8px;
}

.select-role-btn {
	transition: all .2s;
}

.select-role-btn:hover {
	transform: scale(1.06);
}

.role-wrapper button.select-role-btn:hover {
    opacity: 1;
}

.content-wrapper > h1, h2 {
	margin: 10px;
	color: $black;
}

.register-form-wrapper > h1, h2 {
	margin: 10px;
	color: $black;
	padding-top: 20px;
}

.signUp-main-section .signUpType-switcher span {
	text-decoration: underline;
	text-underline-offset: 3.5px;
	font-size: 16px;
	
}

.signUpType-switcher > span:hover{
	transform: scale(1.03);
	text-decoration: underline;
	text-underline-offset: 3.5px;
}


.signUp-main-section .role-logo-wrapper {
	margin-bottom: 20px;
}

.signUp-main-section .role-logo-wrapper.manager>img {
	padding-left: 10px;
}

.ant-space-align-center > .ant-space-item{
	padding-bottom: 20px;
}

.signUp-notice{
	line-height: 23px;
    text-align: center;
    padding-top: 15px;
    color: $black;
}

#register-form_password {
    padding: 6.5px 11px;
    padding-top: 6.5px;
    padding-right: 11px;
    padding-bottom: 6.5px;
    padding-left: 11px;
}