.bikes-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1360px;
  padding: 25px 35px;

  @include mq($until: tabletLandscape) {
    max-width: 1100px;
  }

  @include mq($until: tabletPortrait) {
    max-width: 760px;
    padding: 25px 30px;
  }

  @include mq($until: mobileLandscape) {
    max-width: 450px;
    padding: 25px 30px;
  }

  .bikes-main-menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    .bikes-cta-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      width: 720px;
      column-gap: 40px;

      @include mq($until: mobileLandscape) {
        justify-content: flex-start;
      }

      button {
        font-weight: 500;
        padding: 0 55px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        column-gap: 20px;
        height: 67px;

        .icon {
          width: 31px;
        }

        @include mq($until: tabletLandscape) {
          height: 43px;
          padding: 0 35px;
          @include font-size(15px);
          
          .icon {
            width: 25px;
          }
        }
      }

      .bikes-create-new {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 630px) {
          display: none;
        }

        .rotate-hint {
          font-weight: 500;
          font-size: 12px;
          line-height: 26px;
          letter-spacing: 0.16px;
          color: #898989;
        }

        .reservation-hint {
          margin-top: 10px;
          font-weight: 500;
        }
      }
    }
  }

  .bikes-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 50px 0 30px;

    .bikes-filter-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      column-gap: 15px;

      .hint {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #6C6C6C;
      }

      button {
        height: 30px;

        &:hover {
          transform: unset;
        }

        &.selected {
          background: #7A7A7A;
          border-radius: 21px;
          color: $white;
        }

        &.sort-direction {
          border: 1px solid $blue;
          width: 48px;
          min-width: unset;
        }
      }

      @include mq($until: tabletPortrait) {
        display: none;
      }
    }

    @include mq($until: tabletLandscape) {
      max-width: 730px;

      .bikes-filter-wrapper {
        margin-top: 20px;
        width: 100%;
        justify-content: flex-start;
      }
    }

    @include mq($until: tabletPortrait) {
      margin-top: 30px;
    }
  }

  .input-search {
    width: 100%;
    margin-bottom: 30px;
  }

  .empty-list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .empty-list {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #8C8C8C;
    }

    button {
      margin-top: 20px;
      width: 200px;
      height: 35px;
      background: #525252;
      border-radius: 21px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: $white;
    }
  }
}