.bike-configure-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;

  .title-wrapper {
    height: 90px;
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;

    .title-content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;
      margin-left: 25px;

      h2 {
        margin: 0;
        padding: 0;
        font-weight: bold;
      }

      .hint {
        font-size: 18px;
        line-height: 28px;
        color: $light-grey;
      }
    }
  }

  .bike-configure-form {
    display: flex;
    flex-direction: column;
    width: 540px;
    width: 100%;
    column-gap: 40px;
    justify-content: center;
    align-items: center;

    .form-main {
      width: 540px;
      border: 1px solid #D4D4D4;
      border-radius: 9px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 30px;

      .ant-row {
        width: 426px;

        .ant-form-item-label {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: $grey;
        }

        .ant-form-item-control-input {
          min-height: unset;
        }

        .ant-input-number {
          width: 70px;
          height: 38px;
          border: 1px solid $light-grey;
          border-radius: 6px;

          .ant-input-number-input {
            height: 36px;
          }
        }
      }

      .form-switch {
        > .ant-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 20px;

          &.ant-form-item {
            margin-bottom: 25px;
          }

          .ant-form-item-label {
            padding: 0;
          }
        }

        .bike-limit-wrapper, 
        .bike-attempts-wrapper,
        .bike-due-date-wrapper {
          .ant-form-item {
            margin-bottom: 0;

            .ant-input-number {
              margin-bottom: 25px;
            }
          }
        }

        .bike-due-date-wrapper.false {
          .ant-form-item-explain-error {
            display: none;
          }
        }
      }

      .ant-select-selector {
        height: 52px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid $light-grey;
        border-radius: 6px;
      }

      textarea.ant-input {
        border: 1px solid $light-grey;
        border-radius: 6px;
      }
    }

    .cta {
      margin-top: 30px;
      
      .ant-form-item-control-input-content {
        justify-content: space-around;
        flex-direction: row;
        display: flex;
        grid-column-gap: 70px;
        column-gap: 50px;
        align-items: center;

        .primary {
          display: flex;
          flex-direction: row;
          width: 172px;
          justify-content: center;

          &:first-child {
            justify-content: flex-start;
          }

          .arrow {
            width: 73px;
            height: 30px;
          }

          &:last-child {
            justify-content: flex-end;

            .arrow {
              width: 73px;
              height: 20px;
            }
          }

          &.newTab {
            justify-content: center;
          }
        }
      }
    }

    .ant-collapse {
      width: 540px;
      margin-top: 10px;

      .ant-collapse-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .ant-collapse-header {
          width: 540px;
          padding-left: 57px;
          padding-right: 57px;
          border-top: 1px solid #D4D4D4;

          .ant-collapse-arrow {
            right: 57px;
          }
        }

        &.ant-collapse-item-active {
          .ant-collapse-header {
            border-bottom: 1px solid #D4D4D4;
          }
        }
      }

      .ant-collapse-content-box {
        padding-left: 0;
        padding-right: 0;
      }

    }

    .form-advanced {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .form-card {
      width: 426px;
      border: 1px solid #E9E9E9;
      box-sizing: border-box;
      border-radius: 16px;
      margin-bottom: 20px;
      padding: 20px 30px;

      &.form-card-small {
        width: 47%;

        .ant-form-item-control-input-content {
          flex-direction: row;

        }
      }

      .ant-form-item-control-input {
        min-height: 0;
      }

      .ant-picker, .ant-input-number {
        margin: 20px 0;
      }

      .ant-form-item {
        margin-bottom: 0;
      }

      .with-hint {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .hint {
        font-size: 12px;
        line-height: 15px;
        color: rgba(50, 50, 50, 0.62);
      }

      .radio-label-basic {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        column-gap: 15px;

        &.basic {
          color: rgba(0, 0, 0, 0.25);
          cursor: not-allowed;
        }

        .reservation {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          column-gap: 5px;
          font-weight: normal;
          font-size: 11px;
          line-height: 13px;
          color: $green;
        }
      }
    }
  }
}

.bikes-upload-page {
  max-width: 643px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
  
  .bikes-upload-page-callout-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 643px;
    max-height: 209px;
    width: 100%;
    height: auto;
    background: #F5FAFF;
    border-radius: 12px;

    .bikes-upload-page-callout {
      display: flex;
      flex-direction: column;
      padding: 50px 45px;
      row-gap: 10px;

      h2 {
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
        text-align: left;
        color: $blue;
        margin: 0;
        padding: 0;
      }

      p {
        font-weight: normal;
        font-size: 15px;
        line-height: 25px;
        text-align: left;
        color: #737373;
      }
    }
  }

  .dragger-wrapper {
    margin-top: 30px;

    .add-link {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 60px;

      .add-link-label {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $grey;
      }

      .add-link-btn {
        font-size: 16px;
        line-height: 19px;
        text-decoration-line: underline;
        color: $blue;
        cursor: pointer;
      }

      .right-col {
        min-width: 90px;
        text-align: right;
      }
    }

    .ant-upload.ant-upload-drag {
      height: auto;

      .ant-upload-btn {
        height: auto;
      }
    }
    .upload-tabs-wrapper {
      .ant-tabs {
        .ant-tabs-nav {
          &::before {
            border-bottom: unset;
          }
        }
        .ant-tabs-nav-list {
          column-gap: 50px;
        }

        .ant-tabs-tab {
          min-width: 70px;
          width: auto;
          height: 58px;
          border-bottom: unset;

          .ant-tabs-tab-btn {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #262626;

            .upload-tab {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              color: #262626;

              svg path {
                fill: #262626;
                transition: all 0.2s;
              }
              
              &.active {
                color: $blue;

                svg path {
                  fill: $blue;
                  transition: all 0.2s;
                }
              }
            }
          }

          &.ant-tabs-tab-active {
            border-bottom: 3px solid $blue;

            .ant-tabs-tab-btn {
              color: $blue;
            }
          }
        }
      }
    }

    .upload-by-link-wrapper,
    .ant-upload-drag {
      background: $white;
      border: none;
      width: 480px;
    }

    .upload-by-link-wrapper {
      #add-link-form {
        .ant-form-item-control-input-content {
          .input-wrapper-dynamic {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
    
            .dynamic-delete-button {
              left: 15px;
              position: relative;
              width: 0;
            }
          }
        }
      }
    }
  
    .ant-upload-drag-container {
      // height: 160px;
  
      .upload-button-wrapper, 
      .drag-wrapper {
        background: rgba(245, 245, 245, 0.35);
        border: 1px dashed #D9D9D9; 
        border-radius: 6px;
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
  
        &:hover {
          background: #F5F9FF;
          border: 1px solid $blue;
          box-sizing: border-box;
          box-shadow: 0px 4px 14px 1px rgba(0, 0, 0, 0.04);
        }
  
        .ant-upload-text {
          font-size: 16px;
          line-height: 24px;
          color: #262626;
        }
  
        .ant-upload-hint {
          font-size: 14px;
          line-height: 22px;
          color: #8C8C8C;
        }
      }
  
      .drag-wrapper {
        flex-wrap: wrap;
        flex-direction: row;
        margin-bottom: 0;
  
        .drag-inner-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
        .placeholder {
          width: 20px;
        }
        .drop-area {
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  
    .cta-wrapper {
      justify-content: unset;
      align-items: flex-start;
    }

    .scrap-loader-wrapper {
      display: flex;
      flex-direction: column;
      height: 150px;
      align-items: center;
      justify-content: space-evenly;
    }
  
    button.primary {
      padding: 10px 40px;
    }
  }

}

.bike-annotate-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .pdftron-section-wrapper {
    width: calc(100% - 435px);
    height: 100%;

    > .webviewer {
      height: 100%;
    }
  }

  .hidden {
    &#questionTooltip, &#questionsToReorder,
    &#questionType, &#questionNumber,
    &#questionTypeToDelete, &#questionNumberToDelete,
    &#questionTypeToUpdate, &#questionNumberToUpdate {
      height: 0px;
      width: 0px;
      visibility: hidden;  
      display: none;    
    }
  }

  .annotate-main-section {
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .annotate-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    background: $blue;
    position: relative;

    .annotate-header-left,
    .annotate-header-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      column-gap: 30px;
      margin: 0 30px;
    }

    button.back,
    button.share {
      height: 38px;
      padding: 15px 20px;
      font-weight: 500;
      font-size: 15px;
      line-height: 28px;
      color: $blue;
      border: 2px solid $white;
      background: $white;
    }

    button.reservation {
      height: 38px;
      border: 2px solid rgba(255, 255, 255, 0.38);
      padding: 15px 20px;
      font-weight: 500;
      font-size: 15px;
      line-height: 28px;
      color: $white;
      background: $blue;
    }

    .icon {
      margin-right: 10px;
    }

    .annotate-header-right button {
      padding: 15px 30px;

      .icon {
        margin-right: 15px;
        width: 20px;
      }
    }

    .bike-title-input {
      width: 250px;
      height: 38px;
      border: 2px solid rgba(255, 255, 255, 0.45);
      border-radius: 100px;
      color: $white;
      background: $blue;
      font-weight: normal;
      font-size: 15px;
      line-height: 28px;
      padding: 0 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .changes-saved-label {
      font-style: italic;
      font-weight: normal;
      font-size: 15px;
      line-height: 28px;
      color: $white;
    }

    @include mq($until: tabletLandscape) {
      .annotate-header-left,
      .annotate-header-right {
        column-gap: 20px;
        margin: 0 20px;

        button {
          justify-content: space-between;
          font-size: 13px;
          line-height: 18px;
          padding: 10px 15px;
  
          .icon {
            margin-right: 10px;
          }
        }
      }

      .bike-title-input {
        width: 150px;
      }

      .changes-saved-label {
        font-size: 14px;
        line-height: 19px;
      }
    }
  }

  .cta {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
  }
}