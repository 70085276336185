.modal-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 600px;
  min-height: 440px;

  .modal-popup-icon {
    height: 70px;
  }

  .modal-popup-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;

    h2 {
      font-weight: bold;
      padding: 0 0 10px 0;
      max-width: 375px;

      &.delete {
        max-width: 465px;
      }

      .highlight {
        color: $blue;

        &.delete {
          color: $dark-orange;
        }
      }
    }
  
    h5 {
      padding-bottom: 20px;
      max-width: 425px;
    }

    .modal-popup-hint {
      max-width: 350px;
      font-weight: normal;
      font-size: 14px;
      line-height: 133.52%;
      color: rgba(105, 111, 121, 0.72);
    }
  }

  .input {
    width: 425px;
    height: 50px;
  }

  .modal-popup-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 0; 

    button {
      min-width: 170px;
      margin: 0 15px;

      &.delete {
        background: $dark-orange;
        border: 1px solid $dark-orange;
      }

      &.long {
        min-width: 200px;
      }
    }
  }

  @include mq($until: tabletPortrait) {
    width: 560px;

    .input {
      width: 400px;
    }

    .modal-popup-text {
      h2 {
        max-width: 300px;
  
        &.delete {
          max-width: 400px;
        }
      }
    
      h5 {
        max-width: 350px;
      }
  
      .modal-popup-hint {
        max-width: 300px;
        font-size: 13px;
      }
    }
  }

  @include mq($until: mobileLandscape) {
    width: 330px;

    .input {
      width: 300px;
    }

    .modal-popup-icon {
      height: 60px;
    }

    .modal-popup-text {
      h2 {
        max-width: 375px;
  
        &.delete {
          max-width: 465px;
        }
      }
    
      h5 {
        max-width: 400px;
      }
  
      .modal-popup-hint {
        max-width: 350px;
        font-size: 13px;
        margin-bottom: 20px;
      }
    }

    .modal-popup-buttons {
      flex-direction: column;
      row-gap: 30px;
    }
  }

  @include mq($until: mobilePortrait) {
    width: 300px;

    .input {
      width: 270px;
    }

    .modal-popup-icon {
      height: 50px;
    }

    .modal-popup-text {
      h2 {
        max-width: 375px;
  
        &.delete {
          max-width: 465px;
        }
      }
    
      h5 {
        max-width: 400px;
      }
  
      .modal-popup-hint {
        max-width: 350px;
        font-size: 12px;
      }
    }
  }
}