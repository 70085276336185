.reservation-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
  max-width: 700px; // 825px
  width: 100%;
  row-gap: 50px;

  .reservation-page-cancel,
  .reservation-page-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 390px;
    row-gap: 30px;
    margin: 20px 0 40px;

    h3 {
      font-weight: bold;
      font-size: 25px;
      line-height: 35px;
      color: $blue;
      text-align: center;
    }

    .reservation-page-cancel-cta {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      width: 100%;

      .medium {
        padding: 10px 30px;
      }
    }
  }

  .secure-checkout-img {
    width: 450px;
  }

  .invoice-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    row-gap: 20px;

    .external-links {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      column-gap: 20px;
    }
  }

  .payments-wrapper {
    align-self: center;
    width: 400px;
  }
  
  .stripe-form-wrapper {
    display: flex;
    flex-direction: column;
  
    .stripe-form {
      animation: fade 200ms ease-out;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
  
      .ant-form-item {
        margin-bottom: 0;
  
        .ant-form-item-label {
          padding: 0;
        }
      }
    }
  }
    
  .stripe-submit-button.primary {
    padding: 10px 60px;
    align-self: center;
    margin-top: 20px;
  }
  
  .stripe-submit-button.stripe-submit-button-error {
    transform: translateY(15px);
  }
  .stripe-submit-button.stripe-submit-button-error:active {
    transform: scale(0.99) translateY(15px);
  }
  
  .stripe-error-message {
    color: $red;
    display: flex;
    justify-content: center;
    font-size: 16px;
    width: 100%;
    transform: translateY(-15px);
    opacity: 0;
    animation: fade 150ms ease-out;
    animation-delay: 50ms;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .stripe-error-message svg {
    margin-right: 10px;
    cursor: pointer;
  }
  
  .stripe-result {
    margin-top: 50px;
    text-align: center;
    animation: fade 200ms ease-out;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
  
  .stripe-result-cta {
    display: flex;
    justify-content: center;
  
    .primary {
      padding: 10px 60px;
    }
  }
  
  .stripe-result-title {
    color: $blue;
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 25px;
    text-align: center;
    max-width: 370px;
    line-height: 40px;
    align-self: center;
  }
  
  .stripe-result-message {
    color: $black;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 1.6em;
    text-align: center;
  }
  
  .stripe-reset-button {
    border: 0;
    cursor: pointer;
    background: transparent;
  }
  
  @keyframes fade {
    from {
      opacity: 0;
      transform: scale3D(0.95, 0.95, 0.95);
    }
    to {
      opacity: 1;
      transform: scale3D(1, 1, 1);
    }
  }
}

.StripeElement {
  width: 100%;
  padding: 16px 0 16px 15px;
  background: $white;
  border: 1px solid $light-grey;
  box-sizing: border-box;
  border-radius: 6px;
  margin: 35px 0 0;
}