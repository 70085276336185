@import '~sass-mq';

h1, h2, h3, h4, h5, h6, p, span {
    color: $black;
    outline: none;
    margin: 0;

    &.ant-typography {
        color: $black;

        &.heading {
            color: $heading-color;
        }
    }
}

h1 {
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    color: $black;

    @include mq($until: tabletPortrait) {
        font-size: 35px;
        line-height: 42px;
    }

    @include mq($until: mobileLandscape) {
        font-size: 30px;
        line-height: 35px;
    }
}

h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: $black;

    @include mq($until: tabletPortrait) {
        font-size: 25px;
        line-height: 30px;
    }

    @include mq($until: mobileLandscape) {
        font-size: 21px;
        line-height: 30px;
    }
}

h3 {
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    color: $black;

    @include mq($until: tabletPortrait) {
        font-size: 22px;
        line-height: 27px;
    }

    @include mq($until: mobileLandscape) {
        font-size: 18px;
        line-height: 24px;
    }
}

h4 {
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: $black;

    @include mq($until: tabletPortrait) {
        font-size: 19px;
        line-height: 22px;
    }

    @include mq($until: mobileLandscape) {
        font-size: 17px;
        line-height: 18px;
    }
}

h5 {
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: $black;

    @include mq($until: tabletPortrait) {
        font-size: 17px;
        line-height: 20px;
    }

    @include mq($until: mobileLandscape) {
        font-size: 16px;
        line-height: 18px;
    }
}

h6 {
    font-weight: bold;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.16px;
    color: $black;

    @include mq($until: tabletPortrait) {
        font-size: 14px;
        line-height: 15px;
    }

    @include mq($until: mobileLandscape) {
        font-size: 14px;
        line-height: 16px;
    }
}

p {
	color: $black;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.16px;

    @include mq($until: tabletPortrait) {
        font-size: 14px;
        line-height: 19px;
    }

    @include mq($until: mobileLandscape) {
        font-size: 14px;
        line-height: 17px;
    }
}

a {
    font-weight: 500;
    font-size: 15px;

    @include mq($until: tabletPortrait) {
        font-size: 14px;
        line-height: 19px;
    }

    @include mq($until: mobileLandscape) {
        font-size: 14px;
        line-height: 17px;
    }
}

a, .link {
    color: $link-color;
    cursor: pointer;

    &.default:hover {
        color: $link-color;
        text-decoration: underline;
        text-underline-offset: 3.5px;
    }

    // &.default:visited {
    //     color: $link-color;
    //     text-decoration: none;
    // }
}
