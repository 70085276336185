.blocked-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #373538;
  padding: 30px 30px 130px;

  .callout-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 29px;
      font-weight: bold;
      color: $white;
    }

    p {
      color: $white;
      margin-top: 30px;
      width: 270px;
      font-weight: normal;
      font-size: 19px;
      line-height: 28px;
      text-align: center;

      @include mq($until: mobileLandscape) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  .blocked-back {
    width: 108px;
    height: 41px;
    background: #5B5B5B;
    box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.25);
    border-radius: 21px;
  }
}
