button {
    cursor: pointer;
    outline: none;
    outline-style: none;
    border: none;
    user-select: none;
    border-radius: 100px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 105px;

    transition: all .2s ease-in-out;
    color: $white;

    &:hover {
        transform: scale(1.04);
    }

    &:disabled {
        background: #E0E0E0;
        color: #9E9E9E;
        cursor: not-allowed;
    }
}

button.primary {
    height: 44px;
    color: $white;
    background: $blue;
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;
    letter-spacing: 0.16px;
}

button.small {
    height: 27px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding: 5px 20px;
}

button.medium {
    height: 40px;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
}

button.large {
    font-weight: 600;
    height: 48px;
    font-size: 18px;
    line-height: 24px;

    @include mq($until: tabletPortrait) {
        height: 40px;
    }
}

button.dark {
    background: #646464;
}

button.black {
    background: #373737;
    box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.25);
}

button.white {
    background: $white;
    border: 1px solid #7A7A7A;
    box-sizing: border-box;
    border-radius: 21px;
    color: #7A7A7A;

    &:hover {
        background: #7A7A7A;
        border-radius: 21px;
        color: $white;
    }
}

button.green {
    background: $green;
}

button.purple {
    background: $purple;
}

button.withPadding {
    padding: 10px 20px;
    column-gap: 10px;
}

.back-btn {
    color: $white;
    width: 88px;
    height: 30px;
    background: #373737;
    border-radius: 21px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    min-width: unset;

    &:hover {
        transform: unset;
    }
}

.signUp-btn {
    padding: 10px 40px;
}

button:disabled {
    background: #E0E0E0;
    color: #9E9E9E;

    &:hover {
        transform: unset;
    }
}

button.link {
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.16px;
    color: $link-color;
    background: unset;
    height: 45px;
    padding: 15px 20px;

    &:hover {
        transform: unset;
        color: $link-color;
        text-decoration: underline;
        text-underline-offset: 3.5px;
    }
}