.overall-report-page {
  max-width: 1260px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 25px 30px;

  .selector-wrapper {
    margin: 30px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 20px;
    width: 100%;
    justify-content: center;

    &.bike, &.user {
      align-items: flex-start;
      justify-content: flex-start;
      row-gap: 15px;
      width: 100%;

      .ant-select {
        width: 390px;

        @include mq($until: tabletLandscape) {
          width: 50%;
        }
        @include mq($until: tabletPortrait) {
          width: 75%;
        }
        @include mq($until: mobileLandscape) {
          width: 100%;
        }
      }

      .hint {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: $blue;
      }

      .selector-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        column-gap: 20px;
        width: 100%;

        .reservation-date {
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: $grey;
        }

        @include mq($until: tabletPortrait) {
          flex-wrap: wrap;

          .reservation-date { 
            margin-top: 10px;
          }
        }
      }
    }

    .select-button {
      cursor: pointer;

      .arrow {
        height: 35px;
        width: 35px;
        border-radius: 50px;
        background-color: $blue;
      }

      &.disabled {
        cursor: default;
        
        .arrow {
          background-color: #BCBCBC;
        }
      }

    }
    .ant-select {
      width: 50%;

      @include mq($until: tabletPortrait) {
        width: 65%;
      }

      @include mq($until: mobileLandscape) {
        width: 100%;
      }

      .ant-select-selector {
        height: 40px;
        align-items: center;

        .ant-select-selection-item {
          font-size: 22px;
          color: #262626;
          font-weight: 500;
        }
      }

      &:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: #D9D9D9;
      }
    }
  }

  .no-reservations {
    margin-top: 20px;
  }

  .search-actions {
    display: flex;
    justify-content: center;
    flex-direction: row;
    column-gap: 40px;
    width: 100%;

    .input-search {
      align-self: flex-start;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 10px;
      width: 100%;

      .search-label {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $grey;
      }

      .ant-input-affix-wrapper {
        width: 500px;
        height: 52px;
        border: 1px solid $grey;
        border-radius: 6px;

        &:hover, &:focus {
          border-color: $grey;
          box-shadow: unset;
        }
      }

      .ant-input-affix-wrapper-focused {
        border-color: $grey;
        box-shadow: unset;
      }

      @include mq($until: tabletPortrait) {
        width: 50%;

        .ant-input-affix-wrapper {
          width: 100%;
        }
      }

      @include mq($until: mobileLandscape) {
        width: 100%;
      }
    }

    .rotate-hint {
      display: flex;
      align-items: flex-end;
      column-gap: 20px;
      margin-bottom: 7px;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #4383F0;
    }
  }

  .ant-table-wrapper {
    margin-top: 40px;
    width: 1200px;
    align-self: flex-start;

    .view-report {
      color: $link-color;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      &:hover {
        color: $link-color;
        text-decoration: underline;
        text-underline-offset: 3.5px;
      }
    }

    .name-col {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; 
    }
  }

  .table-scroll-hint {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: $grey;
    margin-top: 30px;
  }

  @include mq($until: mobileLandscape) {
    padding: 20px;
  }
  @include mq($until: mobilePortrait) {
    padding: 20px 10px;
  }
}

.confirm-dialog-view-results-cta {
  background: $blue;
  border-color: $blue;

  span {
    color: $black;
  }

  &:hover {
    background: $blue;
    border-color: $blue;

    span {
      color: $white;
    }
  }
}