.bikes-list-wrapper {
  width: 100%;
  margin: 30px 0;

  &.ant-list-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 30px;
    row-gap: 30px;
    flex-wrap: wrap;

    @include mq($until: tabletPortrait) {
      justify-content: center;
    }
  }

  .ant-card.ant-card-bordered {
    box-shadow: unset;
    border-radius: 12px;
    border: 1px solid #D9D9D9;

    &.manager-view {
      min-height: 432px;
      height: auto;
    }
    &.user-view {
      height: auto
    }

    .ant-card-body {
      padding: 0;
      height: 100%;
    }
  }

  .bike-card-manager {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;

    .bike-card-header {
      height: 43px;
      width: 100%;
      background: rgba(141, 192, 252, 0.15);
      border-radius: 12px 12px 0px 0px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      
      a {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 15px;
      }
    }

    .bike-card-body {
      padding: 0 25px;
      margin: 10px 0 20px 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      row-gap: 15px;

      .bike-card-preview {
        width: 250px;
        height: 200px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .skeleton-bikePreview {
          .ant-skeleton-avatar {
            width: 250px;
            height: 200px;
            border-radius: 7px;
          }
        }

        .ant-image {
          width: 250px;
          height: 200px;

          .ant-image-img {
            height: 230px;
            object-fit: contain;
          }
        }

        .full-card-loader {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 90px auto;
        }

        .ant-image-img {
          object-fit: cover;
          object-position: top;
          max-height: 200px;
        }
      }

      .bike-card-title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        row-gap: 5px;

        p {
          font-weight: 600;
          color: #262626;
          height: 44px;
          
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
          word-break: break-word;
        }

        .hint, .location {
          font-weight: normal;
          font-size: 13px;
          line-height: 22px;
          color: #8C8C8C;
        }

        .location {
          color: #096dd9;
        }
      }

      .bike-card-cta {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .bike-preview-button {
        button {
          column-gap: 10px;
        }
      }
    }

    .bike-card-footer {
      height: 43px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .class-card-actions {
        height: 43px;
        width: 276px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        border-top: 1px solid #D9D9D9;
      }
    }
  }

  .bike-card-user {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;

    .bike-card-header {
      height: 43px;
      width: 100%;
      background: rgba(242, 153, 74, 0.05);;
      border-radius: 12px 12px 0px 0px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      
      p {
        font-weight: 600;
        color: $orange;
      }

      &.true, &.rate {
        background: rgba(26, 159, 87, 0.05);

        p {
          color: $green;
        }
      }
    }

    .bike-card-body {
      padding: 0 25px;
      margin: 20px 0 25px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      row-gap: 15px;
      // height: 175px;

      .bike-card-title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        p {
          font-weight: 600;
          color: #262626;

          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
      }

      .bike-card-subtitle {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        p {
          font-weight: 500;
          font-size: 15px;
          color: #262626;
          height: 135px;

          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box !important;
          line-clamp: 6;
          -webkit-line-clamp: 6;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
      }

      .bike-card-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        column-gap: 8px;
        max-height: 22px;

        p, .location {
          font-weight: normal;
          font-size: 14px;
        }
      }

      .bike-cta {
        height: 30px;
        padding: 5px 20px;

        .arrow {
          height: 13px;
        }
      }

      .bike-card-preview {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .ant-image {
          .ant-image-img {
            height: 230px;
            object-fit: contain;
            object-position: top;
            max-height: 230px;
          }
        }

        .full-card-loader {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 90px auto;
        }
      }
    }
  }
}