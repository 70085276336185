.dashboard-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1134px;
  padding: 25px 0;

  @include mq($until: tabletLandscape) {
    max-width: 900px;
    padding: 25px 35px;
  }

  @include mq($until: tabletPortrait) {
    max-width: 760px;
    padding: 25px 30px;
  }

  @include mq($until: mobileLandscape) {
    max-width: 450px;
    padding: 25px 30px;
  }

  .dashboard-main-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 310px;
  }
}