.landing-main-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  .landing-top-menu {
    margin: 10px;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: center;

    a {
      font-size: 20px;
    }
  }

  .landing-main-image {
    .main-image {
      width: 127%;
    }
  }

  .landing-callout {
    background-color: #009FE3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10%;

    h1, a {
      color: #ffffff;
      font-family: "Source Sans Pro", -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
      font-size: 28px;
      font-weight: 700;
      line-height: 30px;
    }
  }

  .landing-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 40px 10% 60px;
    font-family: "Open Sans", Helvetica, Arial, Lucida, sans-serif;
    font-weight: normal;
    line-height: 24px;
    color: rgb(102, 102, 102);

    &.grey {
      background-color: #f2f2f2;
    }

    &.dark {
      background-color: rgba(34,34,34, 0.75);
      color: #ffffff;

      h2, h4, a {
        color: #ffffff;
      }

      a {
        font-size: 20px;
      }
    }

    h2 {
      color: #666666;
      font-size: 26px;
      font-weight: normal;
      line-height: 26px;
    }
    h4 {
      color: #333333;
      font-size: 23px;
      font-weight: normal;
      line-height: 26px;
      margin: 20px 0;
    }
    p {
      color: #333333;
      font-size: 16px;
      font-weight: normal;
      line-height: 25px;
    }

    .bike-cards {
      display: flex;
      flex-direction: row;
      column-gap: 50px;
      row-gap: 50px;
      align-items: center;
      justify-content: center;
      padding: 20px 10px;
      flex-wrap: wrap;

      .bike-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        max-width: 300px;
        color: #ffffff;
        height: 777px;

        &:nth-child(3n + 1) {
          background-color: #e89908;
        }
        &:nth-child(3n + 2) {
          background-color: #c73921;
        }
        &:nth-child(3n + 3) {
          background-color: #5b5a6a;
        }

        .bike-card-header {
          color: #ffffff;
          font-size: 16px;
          font-weight: normal;
          line-height: 26px;
          margin: 20px 0;
        }
        .bike-card-img {
          width: 300px;
          height: 195px;
          object-fit: contain;
          background-color: #ffffff;
        }
        h4 {
          color: #ffffff;
          font-size: 18px;
          font-weight: normal;
          line-height: 26px;
          margin: 20px 0 10px;
          padding: 0 25px;
        }
        p {
          color: #ffffff;
          font-size: 14px;
          font-weight: normal;
          padding: 0 25px;
        }
        a {
          padding-bottom: 20px;
        }
      }
    }
  }
}