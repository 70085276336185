.manage-page {
  max-width: 1055px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0;
  padding: 0 30px;

  .manage-tabs-wrapper {
    margin: 50px 0 30px;

    .ant-tabs {
      .ant-tabs-nav-list {
        column-gap: 65px;
      }

      .ant-tabs-tab {
        width: 175px;

        &.ant-tabs-tab-active:nth-child(1),
        &.ant-tabs-tab-active:nth-child(2) {
          border-bottom: 3px solid $purple; 

          .ant-tabs-tab-btn {
            color: $purple;
          }
        }
      }
    }

    @include mq($until: tabletPortrait) {
      align-self: flex-start;
      margin: 30px 0 20px;
    }
    @include mq($until: mobileLandscape) {
      align-self: unset;

      .ant-tabs {
        .ant-tabs-nav-list {
          column-gap: 40px;
        }
        .ant-tabs-tab {
          width: 160px;
        }
      }
    }
  }

  .stats {
    .hint {
      font-weight: normal;
      font-size: 20px;
      line-height: 28px;
      color: #919191;
    }

    @include mq($until: tabletPortrait) {
      align-self: flex-start;
    }
    @include mq($until: mobileLandscape) {
      align-self: unset;
    }
  }

  .counter {
    align-self: flex-start;
    font-weight: bold;
    font-size: 27px;
    line-height: 37px;
    color: $purple;
    margin-bottom: 25px;

    @include mq($until: tabletPortrait) {
      font-size: 20px;
    }
  }

  .join-link-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-bottom: 5px;

    h5 {
      font-weight: bold;
      font-size: 18px;
      line-height: 16px;
      letter-spacing: 0.16px;
    }

    .actions {
      font-weight: 500;
      font-size: 18px;
      line-height: 16px;
      color: $blue;

      .re-generate, .link {
        cursor: pointer;
        color: $blue;

        &:hover {
          text-decoration: underline;
          text-underline-offset: 3.5px;
        }
      }
    }

    @include mq($until: tabletPortrait) {
      align-self: flex-start;
      margin: 20px 0 40px;
    }
    @include mq($until: mobileLandscape) {
      align-self: center;
      text-align: center;
    }
  }

  .invite-btn {
    margin-bottom: 10px;

    @include mq($until: tabletPortrait) {
      height: 35px;
      align-self: flex-start;
      margin: 15px 0 30px;
    }
    @include mq($until: mobileLandscape) {
      align-self: center;
      text-align: center;
    }
  }

  .search-actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    column-gap: 30px;
    justify-content: flex-start;

    .input-search {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 10px;
      width: 70%;
  
      .search-label {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $grey;
      }
      
      .ant-input-affix-wrapper {
        max-width: 650px;
        height: 52px;
        border: 1px solid $grey;
        border-radius: 6px;

        &:hover, &:focus {
          border-color: $grey;
          box-shadow: unset;
        }
      }

      .ant-input-affix-wrapper-focused {
        border-color: $grey;
        box-shadow: unset;
      }
    }

    @include mq($until: tabletLandscape) {
      .input-search {
        width: 55%;
        .ant-input-affix-wrapper {
          width: 100%;
          max-width: 600px;
        }
      }
    }
    @include mq($until: mobileLandscape) {
      .input-search {
        width: 100%;
      }
    }
  }

  .ant-table-wrapper {
    margin-top: 40px;
    width: 990px;
    align-self: center;

    .view-report {
      color: $link-color;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      &:hover {
        color: $link-color;
        text-decoration: underline;
        text-underline-offset: 3.5px;
      }
    }

    .remove, .cancel {
      color: $red;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      cursor: pointer;

      &:hover {
        color: $red;
        text-decoration: underline;
        text-underline-offset: 3.5px;
      }
    }
  }

  .table-scroll-hint {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: $grey;
    margin-top: 30px;
  }

  @include mq($until: mobileLandscape) {
    padding: 0 20px;
  }
  @include mq($until: mobilePortrait) {
    padding: 0 10px;
  }

  .editable-cell {
    position: relative;
  }
  
  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }
  
  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
  
  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }

  .users-list-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .add-user-btn {
      margin: 15px 0 0;
    }

    .ant-table-wrapper {
      margin-top: 20px;
    }
  }
}
