.signup-cta-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 10px;

  .google-login-btn, .microsoft-login-btn {
    width: 413px;
    height: 55px;
    text-align: center;
    background: $white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    margin: 15px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    color: #171717;

    @include mq($until: mobileLandscape) {
      width: 350px;
      height: 44px;
    }

    @include mq($until: mobilePortrait) {
      width: 330px;
    }
  }

  .microsoft-login-btn {
    color: $white;
    background: #2F2F2F;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  }

  .signUpType-switcher {
    margin-top: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    color: #151515;

    span {
      font-family: Inter;
      color: #151515;
    }
  }
}

.signup-form-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.signin-form-button {
  display: flex;
  flex-direction: column;
  align-items: center;

  .login-btn {
    width: 100%;
  }

  button.signUp-btn {
    // width: 250px;
    margin-bottom: 10px;
    font-size: 16px;
    transition: all .2s ease-in-out;
  }
}

.sign-in-switcher {
  font-size: 16px;
  margin: 8px;
  max-width: 375px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
}
