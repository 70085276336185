$blue: #247CE3;
$green: #1A9F57;
$red: #CF2121;
$purple: #9D29F9; 
$dark-purple: #7851A9; 
$dark-orange: #F05E23;
$orange: #F2994A;
$white: #FFFFFF;
$black: #000000;
$dark: #393939;
$light-grey: #8692A6;
$grey: #696F79;

$link-color: #096dd9;
$heading-color: #14c6bb;