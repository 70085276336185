.page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 70px;
  min-height: 70px;
  width: 100%;

  .logo {
    width: 300px;

    &:not(.external) {
      @include mq($until: tabletLandscape) {
        display: none;
      }
    }
  }

  .logo-mobile {
    display: none;
  
    @include mq($until: tabletLandscape) {
      display: block;
    }
  }

  .header-left {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @include mq($until: tabletLandscape) {
      justify-content: flex-start;
    }

    @include mq($until: mobileLandscape) {
      justify-content: center;
    }
  }

  .header-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header-middle-label {
      background: #ecf5ff;
      border-radius: 100px;
      color: $blue;
      font-weight: normal;
      padding: 11px 35px;
      font-size: 20px;
      line-height: 20px;

      max-width: 410px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ant-steps-horizontal {
      display: flex;
      flex-direction: row;
      column-gap: 25px;

      .ant-steps-item-container {
        .ant-steps-item-content {
          @include mq($until: tabletPortrait) {
            display: none;
          }
        }
      }
    }
  }

  .header-right {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;

    @include mq($until: mobileLandscape) {
      justify-content: center;
    }

    .header-profile-dropdown-wrapper {
      position: relative;
    }

    .avatar-wrapper {
      @include mq($until: tabletLandscape) {
        display: none;
      }

      width: auto;
      max-width: 260px;
      height: 37px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      background: $blue;
      border-radius: 26px;
      position: relative;
      outline: 0;
      cursor: pointer;
      white-space: nowrap;
      text-align: center;
      user-select: none;
      touch-action: manipulation;
      margin: 0;
      padding: 4px 15px;
      overflow: visible;

      .user-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 230px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.16px;
        color: $white;
        padding-left: 10px;
      }

      .ant-avatar-sm {
        width: 18px;
        height: 19px;
      }
    }

    .main-menu-wrapper {
      display: flex;
      flex-direction: column;
      width: 242px;
      height: auto;
      max-height: 224px;
      position: absolute;
      top: 50px;
      right: 0;
      background: $white;
      box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      z-index: 100;

      .main-menu-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-height: 65px;
        height: auto;
        padding: 5px 10px;
        border-bottom: 1px solid #F2F3F5;

        .icon.close {
          width: 35px;
          height: 35px;
          cursor: pointer;
          align-self: flex-start;
          padding: 0 5px 5px;
        }

        .main-menu-header-title {
          display: flex;
          flex-direction: column;
          max-height: 50px;
          height: auto;

          .main-menu-user-name, .main-menu-user-email {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 180px;
          }

          .main-menu-user-name {
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            color: $blue;
          }

          .main-menu-user-email {
            font-weight: normal;
            font-size: 11px;
            line-height: 28px;
            color: #7E7E7E;
          }
        }
      }

      .main-menu-items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        row-gap: 8px;
        width: 100%;

        .main-menu-item {
          padding-left: 33px;
          height: 34px;
          font-weight: normal;
          font-size: 14px;
          letter-spacing: 0.025em;
          color: $black;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;

          &:hover {
            color: $black;
            text-decoration: none;
            background: #F2F3F5;
          }

          &.reservation {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .label {
              margin-right: 17px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              font-weight: 600;
              font-size: 8px;
              line-height: 28px;
              letter-spacing: 0.025em;
              color: #69696B;
              width: 39px;
              height: 14px;
              background: #F5F5F5;
              border-radius: 18px;

              &.pro {
                background: #EEF6FF;
                color: $blue;
              }
            }
          }
        }
      }
    }
  }
}

.mobile-header-menu-icon {
  position: absolute;
  right: 30px;
  display: none;

  @include mq($until: tabletLandscape) {
    display: contents;
  }

  .menu {
    border-radius: 3px;
    cursor: pointer;
    user-select: none;

    display: block;
    padding: 15px 0;

    span {
      background: #262626;
      display: block;
      width: 24px;
      height: 3px;
      border-radius: 3px;
      transform-origin: center;
      transition: 300ms all cubic-bezier(.4,0,.2,1);

      &:nth-child(2) {
        margin: 4px 0;
      }
    }

    &.toggled {
      span {
        background: #262626;

        &:first-child {
          transform: translateY(6px) rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:last-child {
          transform: translateY(-8px) rotate(-45deg);
        }
      }
    }
  }
}

.mobile-menu {
  width: 390px;
  height: 100%;
  position: absolute;
  top: 70px;
  right: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background: $white;
  box-shadow: 0px 4px 5px 2px rgba(155, 147, 147, 0.25);
  border-radius: 9px;

  .ant-menu {
    padding: 35px 20px;

    .ant-menu-item {
      cursor: pointer;
      font-weight: 600;
      font-size: 17px;
      line-height: 21px;
      color: #333333;

      &:last-child {
        display: none;
      }
    }
  }

  .primary {
    padding: 10px 130px;
    height: 50px;
    font-size: 16px;
    align-self: center;
    margin: 20px 0 0 24px;
  }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(180deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(180deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(180deg); transform:rotate(180deg); } }