#root {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
}

.app-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  @include mq($until: desktop) {
    justify-content: center;
  }
}

._loading_overlay_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.app {
  position: relative;
  width: 100%;
  height: 100%; // calc(100% - 115px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;

  @include mq($until: desktop) {
    width: 100%;
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 0;

  .data {
    margin: $default-spacing * 2;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: $default-spacing / 2;
  bottom: 0;
  height: 15px;
  min-height: 15px;
  align-items: center;
  row-gap: 10px;
  padding: 10px 0;

  a {
    font-size: 14px;
  }

  @include mq($until: mobileLandscape) {
    display: inline;
    line-height: 25px;
    text-align: center;
    height: 50px;
  }
}

.not-found-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80%;
  padding: 0 30px;

  img {
    width: 316px;
  }

  h1 {
    margin: 40px 0;
  }

  .primary {
    width: 190px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    padding: 20px 40px;
  }
}

.forgot-password-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ant-form {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .reset-password-btn {
      align-self: center;
    }
  }
}

.cta-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 30px;
  width: 100%;

  @include mq($until: tabletLandscape) {
    flex-direction: column;
    align-items: center;
    min-height: 200px;
  }
}