.skeleton.class-card-manager, .skeleton.class-card-user {
  .skeleton-classTitle {
    .ant-skeleton-button {
      height: 70px;
      width: 200px;
      border-radius: 7px;
    }
  }

  .skeleton-classAvatar {
    .ant-skeleton-avatar {
      height: 70px;
      width: 70px;
      border-radius: 7px;
    }
  }

  .class-name-wrapper {
    margin-bottom: 15px;
  }

  .class-card-cta {
    margin: 20px 0 5px;
  }

  .skeleton-classActions {
    height: 40px;

    .ant-skeleton-content {
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-skeleton-title {
        height: 30px;
        border-radius: 2px;
      }
    }
  }
}

.bikes-skeleton-wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 30px;
}

.skeleton.bike-configure-form {
  .form-main {
    width: 540px;
    border: 1px solid #D4D4D4;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 57px;

    .skeleton-long {
      .ant-skeleton-title {
        height: 52px;
        margin-top: 10px;
      }

      &.large {
        .ant-skeleton-title {
          height: 100px;
          margin-top: 10px;
        }
      }
      margin-bottom: 10px;
    }
    
    .skeleton-switch {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      column-gap: 25px;
      margin: 10px 0;
      width: 100%;

      .ant-skeleton {
        width: 130px;
      }

      .skeleton-switch-item {
        .ant-skeleton-title {
          height: 25px;
          margin-top: 11px;
        }
      }
    }
  }
}