.profile-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  max-width: 425px;
  width: 100%;

  .switch-role {
    margin-top: 30px;
  }

  .profile-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .change-password {
      border-top: 2px solid #C7C7C7;
      max-width: 395px;
      width: 100%;
      margin-top: 15px;
      padding-top: 20px;

      .change-password-label {
        font-weight: normal;
        font-size: 25px;
        line-height: 30px;
        color: $blue;
        margin: 0;
        padding-bottom: 15px;
      }
    }

    .ant-row {
      max-width: 395px;
      width: 100%;

      @include mq($until: mobileLandscape) {
        max-width: 350px;
      }
    }

    .name-inputs-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      column-gap: 35px;
      max-width: 395px;
      width: 100%;

      @include mq($until: mobileLandscape) {
        flex-direction: column;
        max-width: 350px;
      }
    }

    .primary {
      padding: 10px 60px;
      column-gap: 20px;
    }

  }

  @include mq($until: mobileLandscape) {
    max-width: 350px;
  }
}
