@import '~sass-mq';

@mixin font-size($size) {
  font-size: $size;

  // sass-lint:disable no-duplicate-properties
  font-size: #{$size / 16px}rem;
}

@import './breakpoints';
@import './variables';
@import './colors';
@import './antd';
@import './content';

@import './components/text';
@import './components/button';
@import './components/header';
@import './components/modal';
@import './components/bikeCard';
@import './components/login';
@import './components/skeleton';
@import './components/smallScreen';
@import './components/reservationBanner';
@import './components/dashboardComponents';

@import './pages/login';
@import './pages/dashboard';
@import './pages/manage';
@import './pages/profile';
@import './pages/bike';
@import './pages/report';
@import './pages/reservation';
@import './pages/landing';
@import './updates';


body {
  position: relative; 
  height: 100%;
  width: 100%;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  overflow-x: hidden;

  @include mq($until: mobileLandscape) {
    align-items: center;
  }
}

* {
	font-family: 'Inter', 'Open Sans', -apple-system, sans-serif;
}

iframe {
  display: none !important;
}

.full-page-loader {
  margin: 200px auto;
}
