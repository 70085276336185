.dashboard-reservation {
  width: 361px;
  height: 192px;
  background: #F9F9F9;
  border: 1px solid $white;
  box-sizing: border-box;
  border-radius: 15px;
  display: flex;
  justify-content: space-evenly;
  
  .dashboard-reservation-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 17px 30px;
    width: 361px;
    height: 192px;
  }

  .dashboard-reservation-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h6 {
      color: $green;
    }
  }

  .dashboard-reservation-content {
    margin: 10px 0;
    padding-left: 20px;
    li {
      color: #707070;
      margin: 5px 0;

      .strike {
        text-decoration: line-through;
        color: #707070;
      }
    }
  }

  &.reservation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: $white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    width: 100%;
    max-width: 418px;
    height: 525px;

    .dashboard-reservation-wrapper {
      padding: 24px 0 0 40px;
    }

    .dashboard-reservation-header {  
      font-size: 25px;
      color: $green;
      justify-content: center;
    }

    .dashboard-reservation-content {
      li {
        font-size: 15px;
        line-height: 21px;
      }
    }

    .reservation-banner {
      width: 221;
      height: 205px;
    }

    .medium {
      width: 240px;
      margin-bottom: 30px;
    }

    @include mq($until: mobileLandscape) {
      width: 350px;
    }
  }

  &.success {
    height: 510px;

    .invoice-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 10px;

      p {
        font-weight: 500;
        font-size: 19px;
        line-height: 23px;
        color: #6D6D6D;

        &.reservation-start-date {
          color: $purple;
          text-align: center;
        }
      }
    }

    .external-links {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 15px;
      margin-bottom: 20px;

      .medium {
        margin-bottom: 0;
      }
    }

    .reservation-success-wrapper {
      width: 180px;
      height: 180px;
      background: #F5EAFE;
      border-radius: 1000px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .reservation-success {
        height: 101px;
        width: 79px;
        margin-left: 10px;
      }
    }

    .dashboard-reservation-header {
      font-weight: bold;
      font-size: 20px;
      line-height: 22px;
      color: $purple;
      max-width: 280px;
      text-align: center;
    }
  }

  &.cancelled {
    height: 570px;
  }

  &.expired {
    height: 560px;

    .expiration-note {
      font-weight: 500;
      font-size: 14px;
      color: $red;
      margin-bottom: 15px;
    }

    .medium {
      margin-bottom: 0;
    }
  }

  @include mq($until: mobilePortrait) {
    width: 330px;

    .dashboard-reservation-wrapper {
      width: 330px;
    }
  }
}